import React, { useEffect, useRef, useState, useContext } from 'react';
import { useParams, useSearchParams } from "react-router-dom";
import { toast } from 'react-toastify';
import { Director, View } from '@millicast/sdk';
import { useRecoilState } from "recoil";
import canAutoPlay from 'can-autoplay';
import { Fragment } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { CheckIcon } from '@heroicons/react/24/outline'
import { useTranslation } from 'react-i18next';
import { ReactHeight } from 'react-height';

//import assets
import { ReactComponent as AssembloLogoHor } from "../../assets/img/assemblo-logo-hor.svg";

//import services
import MeetingService from "../../services/meeting.service";
import ChatService from "../../services/chat.service";
import { socket, SocketContext } from "../../services/socket.service";
import VotingService from '../../services/voting.service';

//import components
import ChatComponent from "../../components/chat.component";
import EndedComponent from "../../components/ended.component";
import WaitingComponent from "../../components/waiting.component";
import Spinner from "../../components/ui/spinner.component";

export default function AdminMeetingView() {
    const { t } = useTranslation();

    const mainPlayer = useRef();

    const [status, setStatus] = useState("not started");
    const [chat, setChat] = useState([]);
    const [message, setMessage] = useState("");
    const [meeting, setMeeting] = useState({});
    const [meetingRefresh, setMeetingRefresh] = useState(0);

    const [voting, setVoting] = useState({});
    const [votingSelect, setVotingSelect] = useState("");
    const [globalVotes, setGlobalVotes] = useState({ "yes": 0, "no": 0, "abstain": 0 });


    const { id } = useParams();
    const socketprovider = useContext(SocketContext);
    const [autoplay, setAutoplay] = useState(false);

    const [mainPlaceholder, setMainPlaceholder] = useState(true);

    const [open, setOpen] = useState(true)
    const [beforeChat, setBeforeChat] = useState(0);

    //use effect to load meeting
    useEffect(() => {
        MeetingService.getMeeting(id)
            .then(response => {
                setMeeting(response.data);
                document.title = response.data.title;
            })
            .catch(err => {
                toast.error(err.response.data.message);
            });
    }, []);

    useEffect(() => {
        MeetingService.status(id)
            .then(response => {
                setStatus(response.data);
            })
            .catch(err => {
                console.log(err);
            }
            );

        ChatService.getChats(id)
            .then(response => {
                setChat(response.data);
            }
            );

        VotingService.getVoting(id)
            .then(response => {
                setVoting(response.data);
                VotingService.getAllVotes(response.data.id)
                    .then(response => {
                        setGlobalVotes(response.data);
                    }
                    );
            }
            );
    }, []);



    useEffect(() => {
        socketprovider.connect("admin");
        socketprovider.socket.on('meeting', function (data) {
            if (data.id == id && data.status == "start") {
                setStatus("started");
            }
            if (data.id == id && data.status == "end") {
                setStatus("ended")
            }
        });

        socketprovider.socket.on('chat', function (data) {
            if (data.meeting == id) {
                setChat(chat => [...chat, { user: data.user, message: data.message, created_on: data.created_on }]);
            }
        });

        socketprovider.socket.on('question', function (data) {
            if (data.meeting_id == id) {
                setVotingSelect("");
                setVoting(data);
            }
        });

        socketprovider.socket.on('votes', function (data) {
            if (data.meeting_id == id) {
                setGlobalVotes({
                    yes: data.yes,
                    no: data.no,
                    abstain: data.abstain
                })
            }
        });


        return () => {
            socketprovider.socket.off('meeting');
            socketprovider.socket.off('chat');
            socketprovider.socket.off('question');
            socketprovider.socket.off('votes');
            socketprovider.disconnect();
        }
    }, []);

    //use effect to load main and sub player
    useEffect(() => {
        if (meeting.title && meeting.accounttoken) {
            const tokenGenerator = () =>
                Director.getSubscriber({
                    streamName: meeting.title,
                    streamAccountId: meeting.accounttoken,
                    unauthorizedSubscribe: true,
                });

            const millicastMainPlayer = new View(meeting.title, tokenGenerator);

            millicastMainPlayer.on("track", (event) => {
                if (autoplay) {
                    mainPlayer.current.srcObject = event.streams[0];
                    mainPlayer.current.play();
                    setMainPlaceholder(false);
                }
            });

            //connect to main screen and disable audio
            try {
                millicastMainPlayer.connect({
                    pinnedSourceId: "mainPlayer",
                    disableAudio: false,
                });
            } catch (e) {
                console.log("Connection failed, handle error", e);
                millicastMainPlayer.reconnect();
            }

        } else {
            console.log("No stream or account");
        }
    }, [meeting, meetingRefresh, status]);

    function reloadAutoplay() {
        if (!autoplay) {
            canAutoPlay.video().then(({ result }) => {
                if (result === true) {
                    setAutoplay(true);
                    setMeetingRefresh(meetingRefresh + 1);
                    setOpen(false);
                }
            })
        }
    }

    return (
        <>
            <div className="flex">
                <div className="lg:w-4/5 md:3/4 col-1">
                    <div className="flex h-screen">
                        <video className={`w-full aspect-auto ${mainPlaceholder === true ? "hidden" : "block"}`} autoplay ref={mainPlayer}>
                        </video>
                        {mainPlaceholder === true ?
                            (<div className="w-full h-full bg-gray-800">
                                <div className="mt-96">
                                    <AssembloLogoHor className="h-24 mx-auto mt-4" />
                                    <h1 className="text-center text-white text-lg font-bold">{t("meeting.busy.biepboepbap")}</h1>
                                    <h3 className='text-center text-white text-md font-semibold'>{t("meeting.busy.backsoon")}</h3>
                                    <div className="mt-8">
                                        <Spinner />
                                    </div>
                                </div>
                            </div>
                            ) : null}
                    </div>
                </div>
                <div className="lg:w-1/5 md:1/4 bg-gradient-to-r from-[#392b5f] to-[#321548] h-screen">
                    <ReactHeight onHeightReady={height => setBeforeChat(height)}>
                        <div className="">
                            <AssembloLogoHor className="h-12 mx-auto mt-4" />
                        </div>
                    </ReactHeight>
                    <div className="py-4 px-6">
                        <ChatComponent chat={chat} message={message} setMessage={setMessage} paddingTop={beforeChat} hideInput={true} />
                    </div>
                </div>

                {voting ?
                    voting.live == true && (globalVotes["yes"] > 0 || globalVotes["no"] > 0 || globalVotes["abstain"] > 0)?
                        <div className='absolute bottom-0 z-30 bg-white w-96 flex flex-col px-4 pb-4 rounded left-1/2 transform -translate-x-1/2'>
                                <div className="h-16 w-96 bg-lime-400 -mb-4 -ml-4 rounded-bl flex justify-center items-center shadow-lg">
                                    <div className="w-1/3 h-full bg-lime-400" style={{ width: `${(globalVotes["yes"] / (globalVotes["yes"] + globalVotes["no"] + globalVotes["abstain"])) * 100}%` }}>
                                        <p className="text-white text-center mt-2">
                                            {globalVotes["yes"] > 0 ? `${Math.round((globalVotes["yes"] / (globalVotes["yes"] + globalVotes["no"] + globalVotes["abstain"])) * 100)}%` : null}
                                        </p>
                                        <p className="text-white text-center">
                                            {globalVotes["yes"] > 1 ? globalVotes["yes"] + " stemmen" : globalVotes["yes"] == 1 ? globalVotes["yes"] + " stem" : null}
                                        </p>
                                    </div>
                                    <div className="w-1/3 h-full bg-red-500" style={{ width: `${(globalVotes["no"] / (globalVotes["yes"] + globalVotes["no"] + globalVotes["abstain"])) * 100}%` }}>
                                        <p className="text-white text-center mt-2">
                                            {globalVotes["no"] > 0 ? `${Math.round((globalVotes["no"] / (globalVotes["yes"] + globalVotes["no"] + globalVotes["abstain"])) * 100)}%` : null}
                                        </p>
                                        <p className="text-white text-center">
                                            {globalVotes["no"] > 1 ? globalVotes["no"] + " stemmen" : globalVotes["no"] == 1 ? globalVotes["no"] + " stem" : null}
                                        </p>                                            </div>
                                    <div className="w-1/3 h-full bg-slate-600" style={{ width: `${(globalVotes["abstain"] / (globalVotes["yes"] + globalVotes["no"] + globalVotes["abstain"])) * 100}%` }}>
                                        <p className="text-white text-center mt-2">
                                            {globalVotes["abstain"] > 0 ? `${Math.round((globalVotes["abstain"] / (globalVotes["yes"] + globalVotes["no"] + globalVotes["abstain"])) * 100)}%` : null}
                                        </p>
                                        <p className="text-white text-center">
                                            {globalVotes["abstain"] > 1 ? globalVotes["abstain"] + " stemmen" : globalVotes["abstain"] == 1 ? globalVotes["abstain"] + " stem" : null}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        : null
                    : null}
            </div>
            {!autoplay ? (
                <Transition.Root show={open} as={Fragment}>
                    <Dialog as="div" className="relative z-10" onClose={() => (console.log("Click outside of modal."))}>
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                        </Transition.Child>

                        <div className="fixed inset-0 z-10 overflow-y-auto">
                            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                                <Transition.Child
                                    as={Fragment}
                                    enter="ease-out duration-300"
                                    enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                    enterTo="opacity-100 translate-y-0 sm:scale-100"
                                    leave="ease-in duration-200"
                                    leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                    leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                >
                                    <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
                                        <div>
                                            <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100">
                                                <CheckIcon className="h-6 w-6 text-green-600" aria-hidden="true" />
                                            </div>
                                            <div className="mt-3 text-center sm:mt-5">
                                                <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                                                    {t("meeting.busy.ready")}
                                                </Dialog.Title>
                                                <div className="mt-2">
                                                    <p className="text-sm text-gray-500">
                                                        {t("meeting.busy.readysub")}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="mt-5 sm:mt-6">
                                            <button
                                                type="button"
                                                className="inline-flex w-full justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:text-sm"
                                                onClick={() => reloadAutoplay()}
                                            >
                                                {t("meeting.busy.readybutton")}
                                            </button>
                                        </div>
                                    </Dialog.Panel>
                                </Transition.Child>
                            </div>
                        </div>
                    </Dialog>
                </Transition.Root>
            ) : null}

        </>

    )
}