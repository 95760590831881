import React from 'react';
import io from 'socket.io-client';
import TokenService from './api/token';

// javascript object to store the socket
export const socket = {
    socket: null,
    // function to connect to the socket
    connect: function (id) {
        this.socket = io.connect(
            process.env.REACT_APP_WEBSOCKET_URI,
            {
                extraHeaders: {
                    "X-Auth-LiveToken": TokenService.getLiveToken(),
                    "X-Sock-Id": id
                }
            });
    },
    // function to disconnect from the socket
    disconnect: function () {
        this.socket.disconnect();
    },
    // function to get the socket
    getSocket: function () {
        return this.socket;
    }
}

export const SocketContext = React.createContext();