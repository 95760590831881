import { useState, useEffect } from "react";
import { useRecoilState } from "recoil";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useTranslation } from 'react-i18next';
import { t } from "i18next";

//import services
import MeetingService from "../services/meeting.service";
import meetingAdminService from "../services/meeting.admin.service";
import SignService from "../services/sign.service";

//import datastores
import { meState } from "../data/me.atom";

//import components
import HeaderComponent from "../components/header.component";
import Spinner from "../components/ui/spinner.component";
import Footer from "../components/footer.component";

export default function HomeView() {
    const [me] = useRecoilState(meState);
    const { t, i18n } = useTranslation();

    const [meetings, setMeetings] = useState([]);
    const [signage, setSignage] = useState([]);
    const [contractLoader, setContractLoader] = useState(false);

    const navigate = useNavigate();

    useEffect(() => {
        document.title = "Assemblo - Vergaderingen";
        if (me) {
            if (me.admin === 1) {
                meetingAdminService
                    .getAllMeetings()
                    .then((response) => {
                        setMeetings(response.data);
                    })
                    .catch((err) => {
                        toast.error(err.response.data.message);
                    });
            } else {
                MeetingService.getMeetings()
                    .then((response) => {
                        const meetings = response.data.map(meeting => {
                            if (meeting.rsvp === "online" || meeting.rsvp === "proxy") {
                                setSignage(prev => [...new Set([...prev, meeting.rsvp])]);
                            }
                            return meeting;
                        });
                        setMeetings(meetings);
                    })
                    .catch((err) => {
                        toast.error(err.response.data.message);
                    });
            }
        }
    }, [me]);

    useEffect(() => {
        signage.forEach(type => {
            SignService.getSigns(type)
                .then(response => {
                    response.data.forEach(sign => {
                        let adaptmeeting = meetings.find(meeting => meeting.id === sign.signage_attend.id);
                        if (adaptmeeting) {
                            adaptmeeting.signage = sign;
                            setMeetings(prevMeetings => prevMeetings.map(meeting =>
                                meeting.id === adaptmeeting.id ? adaptmeeting : meeting
                            ));
                        }
                    });
                })
                .catch(err => {
                    console.log(err);
                });
        });
    }, [signage]);

    function joinMeeting(id) {
        MeetingService.joinMeeting(id)
            .then(() => {
                if (i18n.resolvedLanguage === "fr") {
                    navigate("/meeting/" + id + "/?lang=FR");
                } else {
                    navigate("/meeting/" + id + "/?lang=NL");
                }
            })
            .catch((err) => {
                toast.error(err.response.data.message);
            });
    }

    function updateRSVP(id, rsvp) {
        MeetingService.updateRSVP(id, rsvp)
            .then((response) => {
                setMeetings(meetings.map(meeting => {
                    if (meeting.meeting_attend.id === id) {
                        meeting["rsvp"] = response.data.rsvp;
                    }
                    return meeting;
                }));
                if (rsvp === "online" || rsvp === "proxy") {
                    setSignage(prev => [...new Set([...prev, rsvp])]);
                }
            })
            .catch((err) => {
                toast.error(err.response.data.message);
            });
    }

    function signContract(id, rsvp) {
        setContractLoader(true);
        SignService.signContract(id, rsvp, i18n.resolvedLanguage)
            .then((response) => {
                if (response.data.redirect_url) {
                    window.location.href = response.data.redirect_url;
                }
            })
            .catch((err) => {
                toast.error(err.response.data.message);
            });
    }

    return (
        <section className="bg-gray-50 h-full">
            <HeaderComponent
                showMeetingLegend
                text={t("home.header.welcome")}
                subtext={""}
                name={me.firstname + " " + me.lastname}
                explainer={false}
            />
            <main className="w-full absolute -mt-80 z-10">
                <div className="max-w-3xl mx-auto py-6 sm:px-6 lg:px-8">
                    <div className="px-4 py-6 sm:px-0">
                        <section className="text-center px-20">
                            {!me.admin ? (
                                meetings.length ? (
                                    meetings.map((meeting) => (
                                        <MeetingCard
                                            key={meeting.id}
                                            updateRSVP={updateRSVP}
                                            signContract={signContract}
                                            joinMeeting={joinMeeting}
                                            meeting={meeting}
                                            contractLoader={contractLoader}
                                        />
                                    ))
                                ) : (
                                    <div className="mt-40 mb-40">
                                        <Spinner text={t("home.meeting.loading")} />
                                    </div>
                                )
                            ) : (
                                <div className="rounded-2xl mt-4 flex bg-white">
                                    <div className="flex flex-col flex-1 px-4 py-8 justify-center items-center h-full">
                                        <p className="font-black text-black text-xl">{t("home.admin.admin")}</p>
                                        <p className="mt-2">{t("home.admin.youareadmin")}</p>
                                        <Link
                                            className="button-primary-blue text-sm mt-4"
                                            to={"/admin/"}
                                        >
                                            {t("home.admin.toadminpanel")}
                                        </Link>
                                    </div>
                                </div>
                            )}
                            <br />
                            <Footer limited={false} mail={me.email} />
                        </section>
                    </div>
                </div>
            </main>
        </section>
    );
}

const MeetingCard = ({ meeting, updateRSVP, signContract, joinMeeting, contractLoader }) => {
    const { id, title, date } = meeting.meeting_attend;
    const { image } = meeting.meeting_attend.building_meeting;
    const meetingDate = new Date(date);
    const [dateOfMeeting] = meetingDate.toISOString().split("T");
    const timeOfMeeting = meetingDate.getHours() + ":" + (meetingDate.getMinutes() < 10 ? '0' : '') + meetingDate.getMinutes()
    return (
        <div key={id} className="rounded-2xl mt-4 flex bg-white shadow-lg">
            <div style={{ minHeight: "15rem" }} className="relative w-48 rounded-tl-2xl rounded-bl-2xl overflow-hidden">
                {/* <img
                    src={process.env.REACT_APP_API_URI + "file/download/" + image}
                    className="w-52 rotate-20 h-full object-cover absolute -left-16 rounded-tr-2xl"
                /> */}
                <img
                    src={process.env.REACT_APP_API_URI + "file/download/" + image}
                    className="w-52 h-full object-cover absolute"
                />
            </div>

            <div className="flex flex-col flex-1 px-4 py-8 justify-center items-center h-full">
                <p className="font-black text-black text-xl">{title}</p>
                <p className="flex justify-between mx-auto w-8/12 text-sm mt-1 ">
                    <span>{dateOfMeeting}</span>&nbsp;·&nbsp;
                    <span>
                        {t("home.meeting.start")}{" "}<span className="font-bold">{timeOfMeeting}</span>
                    </span>
                </p>
                <div className="mt-2">
                    <span className={`inline-flex items-center rounded-full ${meeting.meeting_attend.started == false && meeting.meeting_attend.ended == false ? "bg-gray-300" : meeting.meeting_attend.ended == true ? "bg-red-200" : "bg-green-200"} px-3 py-0.5 text-xs font-medium ${meeting.meeting_attend.started == false && meeting.meeting_attend.ended == false ? "text-gray-900" : meeting.meeting_attend.ended == true ? "text-red-900" : "text-green-900"}`}>
                        <svg className={`-ml-1 mr-1.5 h-2 w-2 ${meeting.meeting_attend.started == false && meeting.meeting_attend.ended == false ? "text-gray-600" : meeting.meeting_attend.ended == true ? "text-red-600" : "text-green-600"}`} fill="currentColor" viewBox="0 0 8 8">
                            <circle cx={4} cy={4} r={3} />
                        </svg>
                        {meeting.meeting_attend.started == false && meeting.meeting_attend.ended == false ? t("home.meeting.status.notstarted") : meeting.meeting_attend.ended == true ? t("home.meeting.status.ended") : t("home.meeting.status.live")}
                    </span>
                </div>
                <div className="mt-4">
                    {meeting.rsvp === "" && meeting.meeting_attend.ended == false ? (
                        <>
                            <p className="text-sm font-semibold my-2">{t("home.meeting.how")}</p>
                            <div className="flex flex-col gap-1">
                                <div className="flex gap-x-1">
                                    <Link
                                        onClick={() =>
                                            updateRSVP(meeting.meeting_attend.id, "present")
                                        }
                                        className="button-primary-yellow text-sm"
                                    >
                                        {t("rsvp.fysical")}
                                    </Link>

                                    {meeting.meeting_attend.building_meeting.type != "IV" ?
                                        <button
                                            onClick={() =>
                                                updateRSVP(meeting.meeting_attend.id, "online")
                                            }
                                            className="button-primary-blue text-sm"
                                        >
                                            {t("rsvp.online")}
                                        </button>
                                        : <button
                                            onClick={() => updateRSVP(meeting.meeting_attend.id, "not")}
                                            className="button-primary-gray text-sm"
                                        >
                                            {t("rsvp.absent")}
                                        </button>}
                                </div>
                                <div className="flex gap-x-1">
                                    {meeting.meeting_attend.building_meeting.type != "IV" ? <>
                                        <button
                                            onClick={() =>
                                                updateRSVP(meeting.meeting_attend.id, "proxy")
                                            }
                                            className="button-primary-red text-sm"
                                        >
                                            {t("rsvp.proxy")}
                                        </button>
                                        <button
                                            onClick={() => updateRSVP(meeting.meeting_attend.id, "not")}
                                            className="button-primary-gray text-sm"
                                        >
                                            {t("rsvp.absent")}
                                        </button></>
                                        : <button
                                            onClick={() =>
                                                updateRSVP(meeting.meeting_attend.id, "online")
                                            }
                                            className="button-primary-blue text-sm"
                                            style={{ width: "100%" }}
                                        >
                                            {t("rsvp.online")}
                                        </button>}
                                </div>
                            </div>
                        </>
                    ) : meeting.meeting_attend.ended == true ? (
                        <p>
                            {t("home.meeting.ended")}
                        </p>
                    ) : null}


                    <div className="mt-5">
                        {meeting.rsvp == "present"
                            ? <div>
                                <p>{t("home.meeting.fysical")}<br />
                                    {t("home.meeting.seeyou")}</p>
                            </div>
                            : null}

                        {meeting.rsvp == "not"
                            ? <p>{t("home.meeting.offline-noproxy")}</p>
                            : null}

                        {meeting.rsvp == "online" && meeting.meeting_attend.ended == false
                            ? meeting.meeting_attend.building_meeting.type == "BV"
                                ? <p>{t("home.meeting.online")}
                                    <Link
                                        className="button-primary-blue text-sm mt-4"
                                        onClick={() => joinMeeting(meeting.meeting_attend.id)}
                                    >
                                        {t("home.meeting.gotomeeting")}
                                    </Link>
                                </p>
                                : meeting.meeting_attend.building_meeting.type == "VME"
                                    ? meeting.signage.contract_signage
                                        ? meeting.signage.contract_signage.type.includes("online") && meeting.signage.envelope_id != null && meeting.signage.envelope_id != "" && meeting.signage.confirmed == true
                                            ? <p>{t("home.meeting.signsuccess")}
                                                <Link
                                                    className="button-primary-blue text-sm mt-4"
                                                    onClick={() => joinMeeting(meeting.meeting_attend.id)}
                                                >
                                                    {t("home.meeting.followonline")}
                                                </Link>

                                            </p>
                                            : <p>
                                                {t("home.meeting.online-failedsign")}{" "}
                                                {contractLoader
                                                    ? <div className="mt-4">
                                                        <Spinner />
                                                    </div>
                                                    : <Link
                                                        className="button-primary-blue text-sm mt-4"
                                                        onClick={() =>
                                                            signContract(meeting.id, "online")
                                                        }
                                                    >
                                                        {t("home.meeting.online-proxy-sign")}
                                                    </Link>}
                                            </p>
                                        : <p>
                                            {t("home.meeting.online-nosign")}{" "}
                                            {contractLoader
                                                ? <div className="mt-4">
                                                    <Spinner />
                                                </div>
                                                : <Link
                                                    className="button-primary-blue text-sm mt-4"
                                                    onClick={() =>
                                                        signContract(meeting.id, "online")
                                                    }
                                                >
                                                    {t("home.meeting.online-proxy-sign")}
                                                </Link>}
                                        </p>

                                    : meeting.meeting_attend.building_meeting.type == "IV"
                                        ? <p>{t("home.meeting.online")} <br />
                                            <Link
                                                className="button-primary-blue text-sm mt-4"
                                                onClick={() => joinMeeting(meeting.meeting_attend.id)}
                                            >
                                                {t("home.meeting.gotomeeting")}
                                            </Link>
                                        </p>
                                        : null
                            : null}

                        {meeting.rsvp == "proxy" && meeting.meeting_attend.ended == false
                            ? meeting.signage.contract_signage
                                ? meeting.signage.contract_signage.type.includes("volmacht") && meeting.signage.envelope_id != null && meeting.signage.envelope_id != "" && meeting.signage.confirmed == true
                                    ? <p>
                                        {t("home.meeting.proxy-signed")}{" "}
                                    </p>
                                    : <p>
                                        {t("home.meeting.proxy-failedsign")}{" "}
                                        {contractLoader
                                            ? <div className="mt-4">
                                                <Spinner />
                                            </div>
                                            : <Link
                                                className="button-primary-blue text-sm mt-4"
                                                onClick={() => signContract(meeting.id, "proxy")}
                                            >
                                                {t("home.meeting.proxy-sign")}
                                            </Link>}
                                    </p>
                                : <p>
                                    {t("home.meeting.proxy-nosign")}{" "}
                                    {contractLoader
                                        ?
                                        <div className="mt-4">
                                            <Spinner />
                                        </div>
                                        : <Link
                                            className="button-primary-blue text-sm mt-4"
                                            onClick={() => signContract(meeting.id, "proxy")}
                                        >
                                            {t("home.meeting.proxy-sign")}
                                        </Link>}
                                </p>
                            : null
                        }

                        <div className="mt-4">
                            {meeting.rsvp !== "" && meeting.meeting_attend.ended == false ? (
                                <Link
                                    onClick={() => updateRSVP(meeting.meeting_attend.id, "")}
                                    className="text-sm mt-6 text-gray-500 hover:text-red-600"
                                >
                                    x {t("home.meeting.change")}
                                </Link>
                            ) : null}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
